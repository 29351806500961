import React from 'react';
import Img from 'gatsby-image';

export default function Player(props) {
  return (
    <div className="player">
      <p className="player-name">{props.name}</p>
      <Img fluid={props.img} />
    </div>
  );
}
