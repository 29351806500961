import React from "react";

import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";
import PlayersContainer from "../components/PlayersContainer";

import "./main.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Theority" />
    <h1 className="title">
      THEORITY
    </h1>
    <div style={{ maxWidth: '350px', margin: 'auto' }}>
      <Image />
    </div>
    <PlayersContainer />
  </Layout >
);

export default IndexPage;
