import React from 'react';
import Player from '../components/player/Player';
import { useStaticQuery, graphql } from 'gatsby';

export default function PlayersContainer() {
  const images = useStaticQuery(graphql`
    query {
      placeholder: file(relativePath: { eq: "placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className="players-container">
      <Player name="Bone Man" img={images.placeholder.childImageSharp.fluid} />
      <Player name="Knighty" img={images.placeholder.childImageSharp.fluid} />
      <Player name="oshiman" img={images.placeholder.childImageSharp.fluid} />
      <Player name="Palm" img={images.placeholder.childImageSharp.fluid} />
      <Player name="Deadpool" img={images.placeholder.childImageSharp.fluid} />
      <Player name="REMYMad" img={images.placeholder.childImageSharp.fluid} />
    </div>
  );
}
